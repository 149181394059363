<template>
  <div id="header">
    <router-link class="logo-container" to="/">
      <img
        v-if="this.theme == 'light'"
        :src="require('../assets/images/main-logo-dark.svg')"
        alt=""
      />
      <img
        v-if="this.theme == 'dark'"
        :src="require('../assets/images/main-logo-light.svg')"
        alt=""
      />
    </router-link>
    <!--     <div class="search-container">
      <div class="search">
        <input type="text" id="seacrh_input" placeholder="Search" />
        <i class="fa-solid fa-magnifying-glass"></i>
        <i class="fas fa-microphone"></i>
      </div>
    </div> -->
    <div class="info-container">
      <div class="notifications">
        <!--         <div class="theme light" @click="changeTheme">
          <img
            v-if="this.theme == 'light'"
            :src="require('../assets/images/theme-light.svg')"
            alt=""
          />
          <img
            v-if="this.theme == 'dark'"
            :src="require('../assets/images/theme-dark.svg')"
            alt=""
          />
        </div> -->
        <!-- <img :src="require('../assets/images/letter.svg')" alt=""> -->
        <!--         <i class="fa-regular fa-envelope"></i>
        <i class="fa-regular fa-bell"></i> -->
        <i class="fa-regular fa-bell" @click="this.notif = !this.notif"></i>
      </div>
      <router-link class="user-name" :to="`/profile?user_id=${userInfo.id}`">
        {{ this.userInfo.name }}
      </router-link>
      <router-link class="user-photo" :to="`/profile?user_id=${userInfo.id}`">
        <img :src="this.userInfo.image" alt="" />
      </router-link>
    </div>
    <Notif v-if="this.notif" @close="this.notif = false"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Notif from "@/components/notifications.vue";
export default {
  components: {
    Notif,
  },
  data() {
    return {
      theme: "light",
      notif: false,
    };
  },
  
  methods: {
    changeTheme: function (e) {
      let those = this;
      let target = document.querySelector(".theme");
      let page_html = document.querySelector("html");
      page_html.classList.toggle("light");
      page_html.classList.toggle("dark");
      target.classList.toggle("light");
      target.classList.toggle("dark");

      setTimeout(function () {
        those.theme == "light" ? (those.theme = "dark") : (those.theme = "light");
      }, 450);
    },
  },
  computed: {
    ...mapGetters(["ACCES_TOKEN", "USER_INFO"]),
    userInfo() {
      return this.USER_INFO;
    },
    accessToken() {
      return this.ACCES_TOKEN;
    },
  },
};
</script>

<style lang="scss">
#header {
  display: flex;
  background: var(--header);
  justify-content: space-between;
  padding: 17px 24px;
  position: fixed;
  top: 0;
  left: 16px;
  right: 16px;
  height: 64px;
  z-index: 2;
  border-radius: 0px 0px 32px 32px;

  .logo-container {
    width: max-content;
  }

  .search-container {
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 7px;
    width: max-content;
    .search {
      position: relative;

      i {
        position: absolute;
        top: 16px;

        color: var(--icon-color);
        font-size: 16px;
        &.fa-microphone {
          right: 16px !important;
        }
        &.fa-magnifying-glass {
          left: 16px !important;
        }
      }

      #seacrh_input {
        width: 640px;
        height: 48px;
        background: var(--nav-hover);
        color: var(--main-text);
        border-radius: 4px;
        padding: 15px 45px;
        border: none;
        outline: none;
        font-size: 14px;
        border-radius: 24px;

        &::placeholder {
          color: var(--main-text);
        }
      }
    }
  }

  .info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .user-photo {
      margin-left: 10px;
      text-decoration: none;
      img {
        width: 24px;
        height: 24px;
        border-radius: 12px;
      }
    }

    .user-name {
      cursor: pointer;
      color: var(--main-text);
      text-decoration: none;
    }

    .notifications {
      margin-right: 20px;
      display: flex;

      i {
        margin-right: 17px;
        margin-top: 3px;
        color: var(--icon-color);
      }

      .theme {
        width: 32px;
        height: 20px;
        left: 8px;
        top: 22px;
        background: var(--theme-track);
        border-radius: 21px;
        margin-right: 17px;
        cursor: pointer;

        img {
          margin-top: -8px;
          transition-duration: 0.5s;
        }

        &.light {
          img {
            margin-left: 13px;
          }
        }

        &.dark {
          img {
            margin-left: 0px;
          }
        }
      }
    }
  }
}
</style>
