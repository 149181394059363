<template>
  <div id="login" class="item-1">
    <div style="position: absolute" class="logo">
      <img :src="require('../assets/images/main-logo.png')" alt="" />
    </div>
    <div class="content">
      <div class="about">
        <div class="title">About Roadie</div>
        <div class="description">
          This social network, unique routes, group races and new adventures at dawn.
        </div>
        <div class="controls">
          <div class="control active" data-item="item-1" @click="activateControl"></div>
          <div class="control" data-item="item-2" @click="activateControl"></div>
          <div class="control" data-item="item-3" @click="activateControl"></div>
        </div>
        <div class="photo-author">
          Photo - Munbaik Cycling Clothing by <span>@munbaik_cycling</span>
        </div>
      </div>
      <div class="auth">
        <div class="auth-container">
          <div class="container auth-desctop">
            <div class="title">To continue, log in to Roadie.</div>
            <div class="description">
              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
              eu fugiat nulla pariatur.
            </div>
            <div class="login-btn" @click="googleAuth()">
              <img :src="require('../assets/images/google-icon.png')" alt="" />
              <div class="text">Continue with Google</div>
            </div>
            <div class="login-btn" @click="appleAuth()">
              <img :src="require('../assets/images/apple-icon.png')" alt="" />
              <div class="text">Continue with Apple ID</div>
            </div>
            <div class="registration">
              <div class="text text-center">Don’t have an acoount?</div>
            </div>
            <div class="reg-btn">Register</div>
            <div class="auth-footer">
              By continuing, you agree to Roadie’s <span>Terms of Service</span> and
              acknowledge you've read our
              <span>Privacy Policy</span>
            </div>
          </div>
          <div class="container auth-mobile">
            <div class="mobile-logo">
              <img :src="require('../assets/images/roadie-logo.png')" alt="" />
            </div>
            <div class="title">Roadie - for iOs and Adroid</div>
            <div class="description">
              Download the free app and start your journey today!
            </div>
            <a
              class="login-btn"
              href="https://play.google.com/apps/testing/com.sadesign.roadie"
            >
              <img :src="require('../assets/images/google-icon.png')" alt="" />
              <div class="text">Continue with Google</div>
            </a>
            <a class="login-btn" href="https://testflight.apple.com/join/ScSjmtW8">
              <img :src="require('../assets/images/apple-icon.png')" alt="" />
              <div class="text">Continue with Apple ID</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { mapActions, mapGetters } from "vuex";
import amplitude from "amplitude-js";
import axios from "axios";
export default {
  name: "login",
  data() {},
  mounted() {
    let those = this;
    let counter = 1;
    setInterval(function () {
      /* console.log(those.activateControl) */

      counter < 3 ? counter++ : (counter = 1);
      let item = document.querySelector(`.control[data-item="item-${counter}"]`);
      /* those.activateControl.call(item) */
      if (item !== null) {
        item.click();
      }
    }, 10000);
  },
  methods: {
    googleAuth() {
      const provider = new GoogleAuthProvider();
      amplitude.getInstance().logEvent("Login");
      signInWithPopup(getAuth(), provider)
        .then((result) => {
          /* console.log(result.user.accessToken) */
          axios(`https://${those.host}/auth/login`, {
            method: "POST",
            data: {
              google_token: result.user.accessToken,
            },
          })
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {});
    },
    appleAuth() {
      amplitude.getInstance().logEvent("Login");
      const provider = new OAuthProvider("apple.com");
      signInWithPopup(getAuth(), provider)
        .then((result) => {
          axios(`https://${those.host}/auth/login`, {
            method: "POST",
            data: {
              google_token: result.user.accessToken,
            },
          })
            .then((response) => {
              console.log(response.data.token);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    activateControl: function (e) {
      let controls = document.querySelectorAll(".control");
      controls.forEach(function (item) {
        item.classList.remove("active");
      });
      e.target.classList.add("active");
      let item_attr = e.target.getAttribute("data-item");
      document.querySelector("#login").removeAttribute("class");
      document.querySelector("#login").classList.add(item_attr);
    },
  },
  computed: {
    ...mapGetters(["HOST"]),
    host() {
      return this.HOST;
    },
  },
};
</script>

<style lang="scss">
#login {
  height: 100vh;
  min-height: 768px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 80px;

  &.item-1 {
    background-image: url("../assets/images/main-bg.png");
  }

  &.item-2 {
    background-image: url("../assets/images/main-bg-1.png");
  }

  &.item-3 {
    background-image: url("../assets/images/main-bg-2.png");
  }
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  .about {
    align-self: end;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 22px;
      color: #ffffff;
    }

    .description {
      margin: 16px 0;
      width: 70%;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
    }

    .controls {
      display: flex;

      .control {
        width: 8px;
        height: 8px;
        background: #ffffff;
        border-radius: 4px;
        margin: 0 4px 0 0;

        &.active {
          transition-duration: 1s;
          width: 24px;
          height: 8px;
          background: #aee140;
          border-radius: 4px;
          animation: control-active 0.5s;
        }
      }
    }
  }
}

@keyframes control-active {
  from {
    width: 8px;
  }

  to {
    width: 24px;
  }
}

.auth {
  display: flex;
  justify-content: flex-end;

  .auth-container {
    width: 509px;
    height: max-content;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(4px);
    border-radius: 4px;
    padding: 63px;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #ffffff;
      margin-bottom: 15%;
    }

    .description {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      margin-bottom: 10%;
    }

    .login-btn {
      width: 100%;
      background: #000000;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      text-decoration: none;
      cursor: pointer;
      margin: 20px 0;

      .text {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #ffffff;
        margin-left: 10px;
      }
    }

    .registration {
      .text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
        margin-top: 10px;
      }
    }

    .reg-btn {
      border: 1px solid #ffffff;
      border-radius: 8px;
      margin: 25px 0;
      padding: 10px;
      display: flex;
      justify-content: center;
      color: #fff;
      cursor: pointer;
    }

    .auth-footer {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      padding-top: 10%;

      span {
        text-decoration: underline;
      }
    }
  }
}

.photo-author {
  padding-top: 60px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;

  span {
    text-decoration: underline;
  }
}

.auth-mobile {
  display: none;
}

@media screen and (max-width: 1180px) {
  .content {
    flex-direction: column-reverse;
    .about {
      align-self: baseline;
    }
  }

  .auth-desctop {
    display: none;
  }

  .auth-mobile {
    display: block;
  }

  .auth {
    .auth-container {
      width: 350px;
      padding: 45px 15px 15px 15px;
      position: relative;
      .mobile-logo {
        position: absolute;
        top: -33px;
        left: 38%;
        right: 50%;
        margin: 0 auto;
      }
    }
  }

  #login {
    padding-left: 30px;
    padding-right: 30px;
  }
}
</style>
