<template>
  <div class="modal-container">
    <div class="custom-modal">
      <div class="modal-head d-flex justify-content-between align-items-center">
        <div class="head-title">{{ $t("modal.create") }}</div>
        <div class="close-btn" style="height: max-content" @click="close_modal()">
          <i class="fas fa-times" style="font-size: 16px"></i>
        </div>
      </div>
      <div class="trip mt-3" v-if="this.trip" @click="test">
        <div class="trip-item">
          <img :src="this.trip[0].track.image" alt="" />
        </div>
        <div class="trip-item">
          <div class="colname">{{ $t("modal.time") }}</div>
          <div class="colvalue">{{ this.trip[0].duration_seconds }}</div>
        </div>
        <div class="trip-item">
          <div class="colname">{{ $t("modal.speed") }}</div>
          <div class="colvalue">
            {{ this.trip[0].speed_kmph }}{{ $t("modal.speed_kmh") }}
          </div>
        </div>
        <div class="trip-item">
          <div class="colname">{{ $t("modal.distance") }}</div>
          <div class="colvalue">
            {{ this.trip[0].distance_metres }}{{ $t("modal.distance_km") }}
          </div>
        </div>
        <div class="trip-item">
          <i class="fas fa-ellipsis-v"></i>
        </div>
      </div>
      <div class="modal-body d-flex flex-column" style="height: 90%">
        <div
          class=""
        >
          
          <label
            for="drop_media"
            class="media-label d-flex flex-column justify-content-center align-items-center mt-2 media-add d-flex flex-column justify-content-center align-items-center"
            style="padding-top: 13px;"
          >
          <i class="fas fa-photo-video" style="font-size: 28px"></i>
            <span style="">{{ $t("modal.add_media") }}</span>
            <p>{{ $t("modal.dragn_drop") }}</p>
          </label>
          <input
            type="file"
            id="drop_media"
            accept=".png, .jpg, .jpeg"
            style="visibility: hidden; height: 0px"
          />
        </div>
        <div class="post-description">
          <textarea
            v-model="post_caption"
            id="post_caption"
            class="w-100"
            style="resize: none"
            :placeholder="$t('modal.caption')"
            name=""
            cols="30"
            rows="10"
          ></textarea>
        </div>
        <div class="media-view row justify-content-center align-items-center"></div>
        <div class="button" style="margin-top: auto" v-if="media_attached">
          <button @click="post_update()">{{ $t("modal.post") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import amplitude from "amplitude-js";
import {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "modal",
  props: ["trip", "caption"],
  data() {
    return {
      uploaded_media: [],
      post_caption: null,
      media_attached: false,
    };
  },
  async mounted() {
    let those = this;
    this.post_caption = this.caption;
    let media_label = document.querySelector(".media-add");
    let file_input = document.querySelector("#drop_media");
    media_label.addEventListener("dragover", function (e) {
      e.preventDefault();
      media_label.classList.add("draged");
    });
    media_label.addEventListener("dragleave", function (e) {
      e.preventDefault();
      media_label.classList.remove("draged");
    });
    media_label.addEventListener("drop", function (e) {
      e.preventDefault();
      media_label.classList.remove("draged");
      let myFile = e.dataTransfer.files[0];
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(myFile);

      file_input.files = dataTransfer.files;
      let changeEvent = new Event("change");
      file_input.dispatchEvent(changeEvent);
    });

    function getVideoCover(file, seekTo = 0.0) {
      console.log("getting video cover for file: ", file);
      return new Promise((resolve, reject) => {
        // load the file to a video player
        const videoPlayer = document.createElement("video");
        videoPlayer.setAttribute("src", URL.createObjectURL(file));
        videoPlayer.load();
        videoPlayer.addEventListener("error", (ex) => {
          reject("error when loading video file", ex);
        });
        // load metadata of the video to get video duration and dimensions
        videoPlayer.addEventListener("loadedmetadata", () => {
          // seek to user defined timestamp (in seconds) if possible
          if (videoPlayer.duration < seekTo) {
            reject("video is too short.");
            return;
          }
          // delay seeking or else 'seeked' event won't fire on Safari
          setTimeout(() => {
            videoPlayer.currentTime = seekTo;
          }, 200);
          // extract video thumbnail once seeking is complete
          videoPlayer.addEventListener("seeked", () => {
            console.log("video is now paused at %ss.", seekTo);
            // define a canvas to have the same dimension as the video
            const canvas = document.createElement("canvas");
            canvas.width = videoPlayer.videoWidth;
            canvas.height = videoPlayer.videoHeight;
            // draw the video frame to canvas
            const ctx = canvas.getContext("2d");
            ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
            // return the canvas image as a blob
            ctx.canvas.toBlob(
              (blob) => {
                resolve(blob);
              },
              "image/jpeg",
              0.75 /* quality */
            );
          });
        });
      });
    }

    file_input.addEventListener("change", function (e) {
      let file = this.files[0];
      let fileToSend;
      let reader = new FileReader();
      let filetype = this.files[0].type.split("/")[0];
      reader.readAsDataURL(this.files[0]);
      reader.onload = () => {
        if (this.files[0].type.split("/")[0] == "video") {
          try {
            // get the frame at 1.5 seconds of the video file
            /*             getVideoCover(file, 1.5).then(function (res) {
              let img = document.createElement("img");
              img.src = URL.createObjectURL(res);
              img.classList.add("col-3");
              document.querySelector(".media-view").appendChild(img);
            }); */
            console.log("video not");
          } catch (ex) {
            console.log("ERROR: ", ex);
          }
        } else {
          let img = document.createElement("img");
          img.src = reader.result;
          img.classList.add("col-3");
          document.querySelector(".media-view").appendChild(img);
          fileToSend = reader.result;
        }
      };
      if (filetype == "image" /* || filetype == "video" */) {
        /*         const storage = getStorage();
        const storageRef = ref(storage, `images/${file.name}_${Date.now()}`); */
        /*         uploadBytes(storageRef, file).then((snapshot) => {
          console.log("Uploaded a blob or file!");
        });
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;
              case "storage/canceled":
                // User canceled the upload
                break;

              // ...

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              axios(`https://${those.host}/post/${those.post_id}/attach/media`, {
                method: "POST",
                headers: {
                  Authorization: those.accessToken,
                },
                data: {
                  type: filetype,
                  url: downloadURL,
                },
              }).then((response) => {
                those.media_attached = true;
              });
            });
          }
        ); */
        axios(`https://${those.host}/post/${those.post_id}/attach/file`, {
          method: "POST",
          headers: {
            Authorization: those.accessToken,
          },
        }).then((response) => {
          /* console.log(response.data.id); */
          console.log(file);
          axios(`https://${those.host}/upload/media/${response.data.id}`, {
            method: "PUT",
            headers: {
              Authorization: those.accessToken,
              "Content-Type": "multipart/form-data",
            },
            data: {
              media_id: response.data.id,
              file: file,
            },
          }).then((response) => {
            console.log(response);
            those.media_attached = true;
          });
        });
      }
    });
  },
  methods: {
    close_modal() {
      this.$emit("close");
    },
    post_update() {
      let those = this;

      if (those.trip !== null) {
        axios(`https://${those.host}/post/${those.post_id}`, {
          method: "PATCH",
          headers: {
            Authorization: those.accessToken,
          },
          data: {
            description: those.post_caption,
          },
        }).then((response) => {
          axios(
            `https://${those.host}/post/${those.post_id}/attach/trip/${those.trip[0].id}`,
            {
              method: "POST",
              headers: {
                Authorization: those.accessToken,
              },
            }
          ).then((response) => {
            axios(`https://${those.host}/post/${those.post_id}/publish`, {
              method: "POST",
              headers: {
                Authorization: those.accessToken,
              },
              data: {},
            }).then((response) => {
              this.$emit("close");
            });
          });
        });
      } else {
        axios(`https://${those.host}/post/${those.post_id}`, {
          method: "PATCH",
          headers: {
            Authorization: those.accessToken,
          },
          data: {
            description: those.post_caption,
          },
        }).then((response) => {
          axios(`https://${those.host}/post/${those.post_id}/publish`, {
            method: "POST",
            headers: {
              Authorization: those.accessToken,
            },
            data: {},
          }).then((response) => {
            this.$emit("close");
          });
        });
      }
      amplitude.getInstance().logEvent("Post created");
    },
    
  },
  computed: {
    ...mapGetters(["NEW_POST", "ACCES_TOKEN", "HOST"]),
    post_id() {
      return this.NEW_POST.post.id;
    },
    accessToken() {
      return this.ACCES_TOKEN;
    },
    host() {
      return this.HOST;
    },
  },
};
</script>

<style lang="scss">
.modal-container {
  position: fixed;
  z-index: 100;
  top: 90px;
  right: 12px;
  bottom: 28px;
  /*   width: 100vw;
  height: 100vh; */
  /*   background: transparent;
  backdrop-filter: blur(6px); */
  display: flex;
  /*   justify-content: flex-end;
  align-items: center; */

  .custom-modal {
    width: 536px;
    /*     height: 500px;
    height: max-content; */
    box-shadow: 0px 4px 12px rgba(24, 28, 35, 0.16);
    background: #fff;
    border-radius: 24px;
    padding: 64px;

    .modal-head {
      .head-title {
        font-size: 32px;
        font-weight: 500;
        line-height: 38px;
      }
    }

    .modal-body {
      margin-top: 37px;

      .media-add {
        background: #f7f7f7;
        border: 1px dashed #000000;
        border-radius: 24px;
        height: 100px;
        cursor: pointer;

        &.draged {
          border-color: blue !important;
          backdrop-filter: blur(6px);
          opacity: 0.6;
        }

        .media-label {
          cursor: pointer;

          span {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            display: flex;
            align-items: center;
            color: #181c23;
          }

          p {
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
            color: #848682;
            margin-bottom: 0;
          }
        }
      }

      .media-view {
        height: 180px;
        overflow-y: auto;

        .col-3 {
          border-radius: 24px;
          margin-top: 10px;
          height: 75px;
        }

        img {
          object-fit: cover;
        }
      }

      .post-description {
        margin-top: 37px;

        textarea {
          height: 100px;
          background: #f7f7f7;
          border-radius: 24px;
          border: none;
          outline: none;
          padding: 12px;
        }
      }

      .button {
        button {
          width: 100%;
          background: #181c23 !important;
          border-radius: 38px;
          color: #fff;
          padding: 8px 12px;
        }
      }
    }
  }

  .trip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    &:hover {
      background: #f1f1f1;
    }
    .trip-item {
      .colname {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        color: #181c23;
      }
      .colvalue {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #181c23;
      }

      img {
        width: 48px;
        height: 48px;
        border: 1px solid #000;
        border-radius: 50%;
      }
    }
  }

    .close-btn {
    display: flex;
    width: 40px;
    height: 40px !important;
    padding: 8.571px;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    background: #f7f7f7;
  }
}
</style>
