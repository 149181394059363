<template class="new-post">
  <div @click="create_post()">
    <slot></slot>
  </div>
  <Modal
    @close="this.modal = false"
    :trip="this.activeTrip"
    :caption="this.newCaption"
    v-if="this.modal"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import Modal from "@/components/modal-component.vue";
export default {
  data() {
    return {
      modal: false,
      activeTrip: null,
      newCaption: null,
    };
  },
  components: {
    Modal,
  },
  props: ["trip", "caption"],
  methods: {
    ...mapActions(["CREATE_NEW_POST"]),
    create_post() {
      let those = this;
      this.modal = true;
      this.activeTrip = this.trip;
      this.newCaption = this.caption;
      this.CREATE_NEW_POST();
    },
  },
  mounted() {
    let those = this;
  },
};
</script>

<style lang="scss"></style>
