<template>
  <div>
    <div v-for="message in messages" :key="message.id">
      {{ message.text }}
    </div>
    <input type="text" v-model="newMessage" @keydown.enter="sendMessage" />
  </div>
</template>

<script>
import * as SDK from "mirrorfly-sdk";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      messages: [],
      newMessage: "",
    };
  },
  async mounted() {
    let those = this;

    function connectionListener(response) {
      if (response.status === "CONNECTED") {
        console.log("SDK has connected to the server successfully.");
      } else if (response.status === "DISCONNECTED") {
        console.log(
          "SDK has disconnected and lost the session. Reconnection was automatically called by the SDK."
        );
      } else if (response.status === "CONNECTIONFAILED") {
        console.log("SDK has failed to connect to the server.");
      } else if (response.status === "RECONNECTING") {
        console.log("SDK has started reconnecting to the server.");
      }
    }

    /*     const initializeObj = {
      apiBaseUrl: `https://roadie-api.mirrorfly.com/api/v1`,
      licenseKey: `zVfjsJg4Ae81u4qWG1oTUMluv2c1em`,
      isTrialLicenseKey: `TRIAL_MODE`,
      callbackListeners: {
        connectionListener,
      },
    }; */
    const initializeObj = {
      apiBaseUrl: "https://roadie-api.mirrorfly.com/api/v1",
      callbackListeners: { connectionListener },
      licenseKey: "zVfjsJg4Ae81u4qWG1oTUMluv2c1em",
      isSandbox: false,
    };

    await SDK.initializeSDK(initializeObj);
    

    if (those.userInfo.length == 0) {
      this.$store.watch(
        () => this.$store.state.user_info,
        (newToken) => {
          if (newToken) {
            SDK.register(newToken.id).then((response) => {
              console.log(response);
              SDK.connect(`${response.data.username}`, `${response.data.password}`).then(
                (response) => {
                  console.log(response);
                }
              );
            });
          }
        }
      );
    }
  },
  computed: {
    ...mapGetters(["ACCES_TOKEN", "USER_INFO", "HOST"]),
    userInfo() {
      return this.USER_INFO;
    },
  },
  methods: {
    handleMessage(message) {
      this.messages.push(message);
    },
    sendMessage() {
      this.newMessage = "";
    },
  },
};
</script>
