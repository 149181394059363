<template>
  <div id="navbar">
    <div class="nav-items">
      <!--       <div class="nav-item new" @click="create_post()">
        <div class="nav-image">
          <img :src="require('../assets/images/new.svg')" alt="" />
        </div>
        New
      </div> -->
      <router-link @click="ampl_main_page()" class="nav-item" to="/">
        <div class="nav-image">
          <i class="fa-solid fa-house"></i>
        </div>
        {{ $t("navbar.home") }}
      </router-link>
      <router-link class="nav-item" @click="ampl_roadies()" to="/roadies">
        <div class="nav-image">
          <i class="fa-solid fa-person-biking"></i>
        </div>
        {{ $t("navbar.roadies") }}
      </router-link>
      <router-link class="nav-item" @click="ampl_activity()" to="/trips">
        <div class="nav-image">
          <i class="fa-solid fa-map-location-dot"></i>
        </div>
        {{ $t("navbar.trips") }}
      </router-link>
      <!--       <router-link class="nav-item" to="/routes">
        <div class="nav-image">
          <i class="fa-solid fa-map-location-dot"></i>
        </div>
        {{ $t("navbar.routes") }}
      </router-link> -->
    </div>
    <div class="settings-items">
      <!--       <div class="nav-item">
        <div class="nav-image">
          <i class="fa-solid fa-gear"></i>
        </div>
        Settings
      </div> -->
      <div class="nav-item" @click="$emit('logout')">
        <div class="nav-image">
          <i class="fa-solid fa-arrow-right-from-bracket"></i>
        </div>
        {{ $t("navbar.logout") }}
      </div>
    </div>
  </div>
</template>

<script>
import amplitude from "amplitude-js";
export default {
  methods: {
    ampl_main_page() {
      amplitude.getInstance().logEvent("Main page view");
    },
    ampl_activity() {
      amplitude.getInstance().logEvent("My activity page view");
    },
    ampl_roadies() {
      amplitude.getInstance().logEvent("Roadies page view");
    },
  },
};
</script>

<style lang="scss">
#navbar {
  width: 320px;
  background: var(--header);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 90px;
  left: 12px;
  bottom: 28px;
  border-radius: 32px;
  z-index: 2;

  .nav-items {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 4px;

    .nav-item {
      padding: 16px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition-duration: 1s;
      font-size: 14px;
      border: 1px solid transparent;
      border-radius: 28px;
      margin: 4px 0;
      color: var(--main-text);
      text-decoration: none;

      &.router-link-active {
        background: #aee140 !important;
      }

      &:hover {
        /*                     border: 1px solid #F7F7F7;
                    border-radius: 8px; */
        background: var(--nav-hover);
        border-radius: 28px;
      }

      .nav-image {
        width: 40px;
        display: flex;
        align-items: center;
      }

      /*  &.new {
        background: #000;
        color: #fff !important;

        &:hover {
          background: #beff33;
        }
      } */
    }
  }

  .settings-items {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 8px;
    .nav-item {
      padding: 16px;
      display: flex;
      align-items: center;
      cursor: pointer;
      border: 1px solid transparent;
      color: var(--main-text);
      text-decoration: none;
      .nav-image {
        width: 40px;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
