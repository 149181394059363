import { createStore } from 'vuex'
import axios from "axios"
import posts from './posts'


export default createStore({
  state: {
    accessToken: null,
    user_info: [],
    new_post: [],
    host: 'api.roadie.com.ua',
    /* host: 'roadie-qa.mircloud.host', */
    lang: 'ua',
  },
  getters: {
    ACCES_TOKEN(state) {
      return state.accessToken
    },
    USER_INFO(state) {
      return state.user_info
    },
    NEW_POST(state) {
      return state.new_post
    },
    HOST(state) {
      return state.host
    },
    LANG(state) {
      return state.lang
    },
  },
  mutations: {
    MUT_ACCES_TOKEN: (state, accessToken) => {
      state.accessToken = accessToken;
    },
    MUT_USER_INFO: (state, user_info) => {
      state.user_info = user_info
    },
    MUT_NEW_POST: (state, new_post) => {
      state.new_post = new_post
    },
    SET_USER_LANG: (state, lang_arr) => {
      state.lang = lang_arr
    },
  },
  actions: {
    SET_ACCES_TOKEN({ commit }, accessToken) {
      commit('MUT_ACCES_TOKEN', accessToken)
    },
    SET_USER_INFO({ commit }, user_info) {
      commit('MUT_USER_INFO', user_info)
    },
    CREATE_NEW_POST({ commit, state }) {
      return axios(`https://${state.host}/post/create`, {
        method: "POST",
        headers: {
          Authorization: state.accessToken,
        },
        data: {
          body: {

          },
        },
      }).then((response) => {
        commit('MUT_NEW_POST', response.data)
      });
    },
    GET_USER_LANG({ commit, state }) {
      let language = navigator.language || navigator.userLanguage;
      language == 'uk-UA' ? lang_arr = 'ua' : lang_arr = 'en'
      this.$i18n.locale = lang_arr;
      commit('SET_USER_LANG', lang_arr)
    },
  },
  modules: {
  }
})

