<template>
  <div class="content">
    <div class="feed col" ref="feed_container" @scroll="handleFeedScroll()">
      <div class="open_post_create" @click="open_post_create()">
        <i class="fas fa-angle-double-down"></i>
      </div>
      <div class="notif d-none" style="">
        <div class="notif-image">
          <img :src="require('../assets/images/notif-img.svg')" alt="" />
        </div>

        <div class="d-flex flex-column" style="width: 380px">
          <div class="notif-title mt-2">
            Hi {{ this.userInfo.name }}, set your parameters.
          </div>
          <div class="notif-description mt-2">
            Add your parameters so we can better tailor the app for you
          </div>
          <div class="buttons">
            <button class="add_parameters">Add my parameters</button>
            <button class="close_notif" @click="close_notif()">Not now</button>
          </div>
        </div>
      </div>
      <div class="new_post d-flex">
        <div class="notif-image">
          <img :src="this.userInfo.image" alt="" />
        </div>
        <div class="d-flex flex-column w-100">
          <div class="caption-input">
            <input
              type="text"
              name=""
              v-model="this.caption"
              :placeholder="$t('modal.caption')"
            />
          </div>
          <div class="post-buttons d-flex justify-content-evenly mt-4">
            <NewPost
              class="d-flex align-items-center"
              :trip="null"
              :caption="this.caption"
            >
              <button type="" class="d-flex align-items-center">
                <i class="fas fa-th mx-2"></i>
                <div>{{ $t("feed.post_create_type") }}</div>
              </button>
            </NewPost>
            <button type="" disabled class="d-flex align-items-center">
              <i class="fas fa-route mx-2"></i>
              <div>{{ $t("feed.training") }}</div>
            </button>
            <button type="" disabled class="d-flex align-items-center">
              <i class="fas fa-calendar-week mx-2"></i>
              <div>{{ $t("feed.trip") }}</div>
            </button>
          </div>
        </div>
      </div>

      <div
        class="feed-content"
        v-for="item in posts"
        :key="item.id"
        :data-id="item.id"
        @mouseover="desc_show = item.id"
        @mouseleave="desc_show = ''"
      >
        <div class="feed-head">
          <router-link
            class="user-info"
            @click="ampl_profile_visit()"
            :to="`/profile?user_id=${item.user.id}`"
          >
            <img
              style="width: 40px; height: 40px; border-radius; border-radius: 94px;"
              :src="item.user.image"
              alt=""
            />
            <div style="margin-left: 5px">
              <div class="post-name">
                {{ item.user.name }}
              </div>
              <div class="post-time">
                {{ item.published_at }}
              </div>
            </div>
          </router-link>
        </div>
        <router-link
          v-if="item.trip.length && item.trip[0].track"
          class="feed-track-info"
          @click="ampl_track_record()"
          :to="`/routes?id=${item.trip[0].track.id}`"
        >
          <i class="fas fa-route"></i>
        </router-link>
        <div class="feed-media">
          <img
            @click="show_post_info(item, item.media[0].url)"
            style="
              min-width: 472px;
              max-width: 855px;
              min-height: 100%;
              cursor: pointer;
              object-fit: cover;
            "
            v-if="item.media.length && item.media[0].type == 'image'"
            :src="item.media[0].url"
            alt=""
          />
          <img
            @click="show_post_info(item, item.media[0].url)"
            style="min-width: 472px; max-width: 855px; min-height: 100%; cursor: pointer"
            v-if="!item.media.length && item.trip[0].track !== null"
            :src="item.trip[0].track.image"
            alt=""
          />
          <div class="feed-description" v-if="item.trip.length">
            <div class="feed-feature">
              <div>
                {{ item.trip[0].distance_metres.toFixed(2) }} {{ $t("feed.meters") }}
              </div>
              <div style="opacity: 0.7; font-size: 10px">{{ $t("feed.distance") }}</div>
            </div>
            <div class="feed-feature">
              <div>
                {{ item.trip[0].elevation_gain_metres.toFixed(2) }}
                {{ $t("feed.meters") }}
              </div>
              <div style="opacity: 0.7; font-size: 10px">
                {{ $t("feed.elevation_gain") }}
              </div>
            </div>
            <div class="feed-feature">
              <div>
                {{ item.trip[0].elevation_loss_metres.toFixed(2) }}
                {{ $t("feed.meters") }}
              </div>
              <div style="opacity: 0.7; font-size: 10px">
                {{ $t("feed.elevation_loss") }}
              </div>
            </div>
            <div class="feed-feature">
              <div>
                {{ item.trip[0].duration_hours }}{{ $t("feed.hours") }}
                {{ item.trip[0].duration_minutes }}{{ $t("feed.minutes") }}
                {{ item.trip[0].duration_seconds }}{{ $t("feed.seconds") }}
              </div>
              <div style="opacity: 0.7; font-size: 10px">
                {{ $t("feed.moving_time") }}
              </div>
            </div>
          </div>
          <!--                  <div class="feed-buttons" v-show="desc_show == item.id">
                            <div class="feed-btn">
                                <i style="font-size: 20px; color: #FF0000;" class="fa-solid fa-fire-flame-curved"></i>
                                <div> {{ item.likeCount }}</div>
                            </div>
                            <div class="feed-btn">
                                <i style="font-size: 20px; color: #fff;" class="fa-regular fa-comment"></i>
                                <div> {{ item.commentCount }}</div>
                            </div>
                            <div class="feed-btn">
                                <i style="font-size: 20px; color: #fff;" class="fa-sharp fa-solid fa-share"></i>
                                <div> {{ item.shareCount }}</div>
                            </div>
                            <div class="feed-btn">
                                <i style="font-size: 20px; color: #fff;" class="fa-solid fa-calendar-week"></i>
                            </div>
                        </div> -->
        </div>
        <div class="row group-media">
          <div
            v-for="(img, index) in item.media"
            class="col-4 mt-3 feed-small-post-media"
          >
            <div>
              <img
                class="feed-small-media"
                style="
                  width: 149px;
                  height: 152px;
                  border-radius: 28px;
                  object-fit: cover;
                  cursor: pointer;
                "
                @click="show_post_info(item, img.url)"
                :src="img.url"
                alt=""
              />
            </div>
          </div>
          <div class="col-4 mt-3" v-if="item.trip.length && item.trip[0].track">
            <img
              class="feed-small-media"
              style="width: 149px; height: 152px; border-radius: 28px; object-fit: cover"
              :src="item.trip[0].track.image"
              alt=""
            />
          </div>
          <div v-if="item.length > 3" @click="show_more" class="open-group">
            +{{ item.hiddenLength }}
          </div>
        </div>
        <div class="d-flex justify-content-between" style="padding: 12px 16px 0">
          <div class="row">
            <div class="col-4 position-relative">
              <i
                style="font-size: 16px; cursor: pointer"
                class="fa-solid fa-fire"
                :class="{ liked: item.my.like }"
                @click="likePost(item.id, item.my.like), (item.my.like = !item.my.like)"
              ></i>
              <div class="comments-count" v-if="item.likes !== 0">
                {{ item.likes }}
              </div>
            </div>
            <div class="col-4 position-relative" @click="open_comments(item)">
              <div class="comments-count" v-if="item.comments !== 0">
                {{ item.comments }}
              </div>
              <i
                style="font-size: 16px; cursor: pointer"
                class="fa-regular fa-comment"
              ></i>
            </div>
            <div class="col-4">
              <!-- <i style="font-size: 16px" class="fa-sharp fa-solid fa-share"></i> -->
            </div>
          </div>
          <div>
            <!-- <i style="font-size: 16px" class="fa-solid fa-calendar-week"></i> -->
          </div>
        </div>
        <div class="feed-footer">
          {{ item.description }}
        </div>
      </div>
    </div>
    <CommentsModal
      :post="this.post_comments"
      :show_more="this.close_comments"
      @close="this.modal = false"
      v-if="this.modal"
    />
    <ShowMore
      v-if="this.active_post"
      :post="this.active_post"
      @like_post="like_from_post"
      @close_info="
        (this.active_post = null), (this.modal = false), (this.close_comments = true)
      "
    />
  </div>
</template>

<script>
import { db } from "@/main";
import { collection, getDocs } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import NewPost from "@/components/create-post-btn.vue";
import CommentsModal from "@/components/comments.vue";
import ShowMore from "@/components/post-info.vue";
import amplitude from "amplitude-js";
/* import i18n from '@/assets/lang/i18n.js'; */
export default {
  components: {
    NewPost,
    CommentsModal,
    ShowMore,
  },
  data() {
    return {
      notif_open: true,
      posts: [],
      caption: null,
      modal: false,
      post_comments: null,
      active_post: null,
      close_comments: true,
      post_offset: 0,
    };
  },
  methods: {
    ampl_track_record() {
      amplitude.getInstance().logEvent("Another user track record page view");
    },
    ampl_profile_visit() {
      amplitude.getInstance().logEvent("Another user Profile Visit");
    },
    go_to_trip(id) {
      this.$emit("go_to_trip", id);
    },
    open_post_create() {
      let post_el = document.querySelector(".new_post");
      let post_btn = document.querySelector(".open_post_create");
      post_el.classList.remove("roof");
      post_btn.classList.remove("roof");
    },
    close_notif() {
      this.notif_open = !this.notif_open;
      localStorage.setItem("notif", Date.now());
    },
    handleFeedScroll() {
      const container = this.$refs.feed_container;
      const scrollPosition = container.scrollTop + container.offsetHeight;
      const scrollHeight = container.scrollHeight;

      if (scrollPosition >= scrollHeight) {
        this.getPostsData();
      }
    },
    show_more: function (e) {
      e.target.parentNode.closest(".group-media").classList.add("open");
      e.target.classList.add("hide");
    },
    open_comments(item) {
      this.post_comments = item;

      if (this.modal == true) {
        this.modal = false;
        setTimeout(() => {
          this.modal = true;
        }, 300);
      } else {
        this.modal = true;
      }
    },
    like_from_post(data) {
      this.likePost(data[0], data[1]);
    },
    likePost(id, liked) {
      let those = this;
      let axios_method;
      amplitude.getInstance().logEvent("Likes");
      liked ? (axios_method = "DELETE") : (axios_method = "POST");

      axios(`https://${those.host}/post/${id}/like`, {
        method: `${axios_method}`,
        headers: {
          Authorization: those.accessToken,
        },
      }).then((response) => {
        console.log(response);
      });
    },
    show_post_info(post, media) {
      this.active_post = {
        post_info: post,
        active_media: media,
      };
      this.post_comments = post;
      this.modal = true;
      this.close_comments = false;
    },
    getPostsData() {
      let those = this;
      let timeInMs = Date.now();
      let notif_timeout = localStorage.getItem("notif");
      if (timeInMs - notif_timeout < 86400000) {
        those.notif_open = false;
      }
      axios(
        `https://${those.host}/feed?start=${(timeInMs / 1000).toFixed(
          0
        )}&limit=10&offset=${those.post_offset}`,
        {
          method: "GET",
          headers: {
            Authorization: those.accessToken,
          },
        }
      )
        .then((response) => {
          those.posts = those.posts.concat(response.data.posts);
          those.post_offset = those.post_offset + 10;
          those.posts.forEach((post) => {
            post.length = post.media.length;
            post.published_at = `${new Date(
              post.published_at * 1000
            ).toLocaleDateString()}`;
            if (post.trip.length) {
              post.length = post.length + 1;
              post.hiddenLength = post.length - 3;
              post.trip[0].duration_hours = `${Math.floor(
                post.trip[0].duration_seconds / 3600
              )}`;
              post.trip[0].duration_minutes = `${Math.floor(
                (post.trip[0].duration_seconds % 3600) / 60
              )}`;
              post.trip[0].duration_seconds = `${post.trip[0].duration_seconds % 60}`;
            }
          });
          /* console.log(those.posts); */
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    ...mapGetters(["ACCES_TOKEN", "USER_INFO", "HOST"]),
    userInfo() {
      return this.USER_INFO;
    },
    accessToken() {
      return this.ACCES_TOKEN;
    },
    host() {
      return this.HOST;
    },
  },
  mounted() {
    /* amplitude.getInstance().init('465608c7f4955d42c298226dceab0b6f'); */
    let those = this;

    if (those.accessToken == null) {
      this.$store.watch(
        () => this.$store.state.accessToken,
        (newToken) => {
          if (newToken) {
            those.getPostsData();
          }
        }
      );
    } else {
      those.getPostsData();
    }

    /*     let feed_el = document.querySelector(".feed ");
    feed_el.addEventListener("scroll", function () {
      let post_el = document.querySelector(".new_post");
      let post_btn = document.querySelector(".open_post_create");
      if (!those.modal) {
        if (post_el.offsetTop > 241) {
          post_el.classList.add("roof");
          post_btn.classList.add("roof");
        } else {
          post_el.classList.remove("roof");
          post_btn.classList.remove("roof");
        }
      }
    }); */
  },
};
</script>

<style lang="scss">
.liked {
  color: #ea4335;
}

.content {
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 65px 0 0;
  .feed {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 24px;

    .notif {
      width: 640px;
      height: 132px;
      background: var(--notif);
      border: 1px solid var(--notif);
      border-radius: 28px;
      padding: 4px;
      margin: 0 auto;
      position: sticky;
      top: 0;
      z-index: 99;

      .notif-image {
        padding: 0 14px 0 0;
      }

      .notif-title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: var(--main-text);
      }

      .notif-description {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: var(--main-text);
      }

      .buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 14px 0 0;

        .add_parameters {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 11.5px 0;
          width: 184px;
          background: var(--notif-button-bg);
          border-radius: 38px;
          border: none;
          color: var(--header);
        }

        .close_notif {
          padding: 2px 0;
          width: 184px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: transparent;
          border: 1px solid var(--icon-color);
          border-radius: 38px;
          color: var(--main-text);
        }
      }
    }

    .new_post {
      width: 480px;
      height: 132px;
      background: var(--notif);
      border: 1px solid var(--notif);
      border-radius: 28px;
      padding: 4px;
      margin: 20px auto;
      position: sticky;
      top: 0;
      z-index: 99;

      &.roof {
        visibility: hidden;
      }

      .notif-image {
        padding: 0 14px 0 0;
        width: max-content;
        img {
          width: 56px;
          height: 56px;
          border-radius: 50%;
        }
      }

      .caption-input {
        width: 100%;
        input {
          width: 100%;
          background: #f7f7f7;
          border-radius: 38px;
          height: 56px;
          border: none;
          padding: 18px 16px;
          outline: none;
          &::placeholder {
            color: #2d3441;
          }
        }
      }

      .post-buttons {
        font-size: 14px;
        button {
          border: none;
          outline: none;
          background: transparent;
        }
      }
    }

    .open_post_create {
      background: rgba(24, 28, 35, 0.48);
      -webkit-backdrop-filter: blur(4px);
      backdrop-filter: blur(4px);
      width: 48px;
      height: 48px;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      position: absolute;
      color: #fff;
      top: 58px;
      left: 0;
      right: 0;
      z-index: 99;
      margin: 0 auto;
      font-size: 25px;
      cursor: pointer;
      visibility: hidden;
      &.roof {
        visibility: visible;
      }
    }

    .feed-content {
      margin: 24px auto 0;
      width: 480px;
      padding: 4px;
      background: var(--bg-block);
      border-radius: 32px;
      position: relative;
      .feed-small-post-media {
        &:first-child {
          display: none !important;
        }
      }
      .feed-media {
        width: 472px;
        height: 472px;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 28px;

        .feed-small-media {
        }
      }

      .feed-description {
        background: rgba(24, 28, 35, 0.48);
        backdrop-filter: blur(8px);
        border-radius: 24px;
        width: 99%;
        position: absolute;
        bottom: 4px;
        left: 4px;
        right: 4px;
        padding: 13px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition-duration: 0.6s;

        .feed-feature {
          color: #ffffff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 25%;
          border-right: 1px solid rgba(255, 255, 255, 0.4);

          &:last-child {
            border-right: none;
          }
        }
      }

      .group-media {
        width: 484px;
        max-height: 180px;
        overflow: hidden;
        position: relative;
        .open-group {
          position: absolute;
          right: 0px;
          top: 15px;
          width: 150px;
          height: 152px;
          border-radius: 28px;
          background: rgba(24, 28, 35, 0.64);
          display: flex;
          justify-content: center;
          align-items: center;
          font-style: normal;
          font-weight: 400;
          font-size: 32px;
          line-height: 38px;
          color: #ffffff;
          cursor: pointer;
          &.hide {
            display: none;
          }
        }
        &.open {
          max-height: max-content;
        }
      }

      .feed-buttons {
        position: absolute;
        top: 100px;
        right: 29px;

        .feed-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          color: #fff;
          margin: 10px 0;
        }
      }

      .feed-head {
        padding: 4px 12px 4px 4px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 8px;
        left: 10px;
        z-index: 2;
        background: rgba(24, 28, 35, 0.48);
        backdrop-filter: blur(8px);
        border-radius: 24px;

        .user-info {
          display: flex;
          width: max-content;
          justify-content: space-between;
          align-items: center;
          text-decoration: none;

          .post-name {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #fff;
          }

          .post-time {
            font-family: "Barlow", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #fff;
          }
        }
      }

      .feed-track-info {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 2;
        background: rgba(24, 28, 35, 0.48);
        backdrop-filter: blur(4px);
        width: 48px;
        height: 48px;
        border-radius: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;
        i {
          color: #fff;
          font-size: 16px;
        }
      }

      .feed-footer {
        padding: 12px 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.02em;
        color: #080808;
      }
    }

    .comments-count {
      position: absolute;
      top: 0;
      right: 0;
      background: #aee140;
      border-radius: 42px;
      width: max-content;
      padding: 1px 4px;
      font-size: 8px;
      line-height: 10px;
      color: #181c23;
    }
  }

  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  ::-webkit-scrollbar-button {
    background-color: #666;
    height: 0;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: var(--blackColor);
  }

  ::-webkit-scrollbar-thumb {
    height: 50px;
    background-color: #666;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-corner {
    background-color: #999;
  }

  ::-webkit-resizer {
    background-color: #666;
  }
}
</style>
