import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n';
import messages from '@/assets/lang/i18n.js';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import amplitude from 'amplitude-js';



// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

amplitude.getInstance().init('465608c7f4955d42c298226dceab0b6f');

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD6q2dio8N3D_oUtmrTJ9b2Gj37xpedbxE",
  authDomain: "roadie-a26fe.firebaseapp.com",
  projectId: "roadie-a26fe",
  storageBucket: "roadie-a26fe.appspot.com",
  messagingSenderId: "298040356249",
  appId: "1:298040356249:web:79922fe04c35d2ef093708",
  measurementId: "G-CS73ZMEL7T"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

let lang_arr;
let language = navigator.language || navigator.userLanguage;
language == 'uk-UA' ? lang_arr = 'ua' : lang_arr = 'en'

const i18n = createI18n({
  locale: lang_arr,
  messages: messages
});


createApp(App).use(store).use(router).use(i18n).mount('#app')

export {db}
