<template>
  <div class="modal-container">
    <div class="custom-modal">
      <div class="modal-head d-flex justify-content-between align-items-center">
        <div class="head-title">{{ $t("comments.comments") }}</div>
        <div
          class="close-btn"
          v-if="this.show_more"
          style="height: max-content"
          @click="close_modal()"
        >
          <i class="fas fa-times" style="font-size: 16px"></i>
        </div>
      </div>
      <div class="modal-body" ref="container" @scroll="handleScroll()">
        <div class="comments">
          <div class="comment-item position-relative" v-for="item in comments">
            <router-link
              class="user-info"
              @click="ampl_profile_visit()"
              :to="`/profile?user_id=${item.user.id}`"
            >
              <div class="d-flex">
                <img
                  style="width: 40px; height: 40px; border-radius; border: 0.935897px solid #AEE140; border-radius: 94px;"
                  :src="item.user.avatar_url"
                  alt=""
                />
                <div style="margin-left: 5px">
                  <div class="post-name">
                    {{ item.user.name }}
                  </div>
                  <div class="comment-time">
                    {{ item.created_at }}
                  </div>
                </div>
              </div>
            </router-link>
            <div
              class="position-absolute"
              style="top: 0; right: 15px"
              @click="like_comment(item.id, item.my), (item.my.like = !item.my.like)"
            >
              <i
                class="fa-solid fa-fire fire-white"
                :class="{ active: item.my.like }"
              ></i>
            </div>
            <div class="comment-text" @click="click_mention" v-html="item.text"></div>
            <div class="d-flex">
              <div
                class="reply_comment"
                @click="
                  (this.parent_comment = item.id), (this.parent_name = item.user.name)
                "
              >
                Відповісти
              </div>
              <div
                class="reply_comment"
                v-if="item.replies"
                @click="get_replies(item.id)"
              >
                Подивитись відповіді ({{ item.replies }})
              </div>
            </div>
            <div
              class="comments"
              v-if="reply_parent == item.id"
              style="margin-left: 30px"
            >
              <div class="comment-item position-relative" v-for="item in replies">
                <router-link class="user-info" :to="`/profile?user_id=${item.user.id}`">
                  <div class="d-flex">
                    <img
                      style="width: 40px; height: 40px; border-radius; border: 0.935897px solid #AEE140; border-radius: 94px;"
                      :src="item.user.avatar_url"
                      alt=""
                    />
                    <div style="margin-left: 5px">
                      <div class="post-name">
                        {{ item.user.name }}
                      </div>
                      <div class="comment-time">
                        {{ item.created_at }}
                      </div>
                    </div>
                  </div>
                </router-link>
                <div
                  class="position-absolute"
                  style="top: 0; right: 15px"
                  @click="like_comment(item.id, item.my), (item.my.like = !item.my.like)"
                >
                  <i
                    class="fa-solid fa-fire fire-white"
                    :class="{ active: item.my.like }"
                  ></i>
                </div>
                <div class="comment-text" @click="click_mention" v-html="item.text"></div>
                <div class="d-flex">
                  <div
                    class="reply_comment"
                    @click="
                      (this.parent_comment = item.id),
                        (this.parent_name = item.user.name),
                        (new_comment = `@${item.user.nick} `)
                    "
                  >
                    Відповісти
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="user-info">
          <img
            style="width: 40px; height: 40px; border-radius; border: 0.935897px solid #AEE140; border-radius: 94px;"
            :src="this.userInfo.image"
            alt=""
          />
        </div>
        <div class="new-comment col">
          <div
            class="position-absolute mention-list"
            style="bottom: 50px; background: #fff; padding: 8px"
            v-if="this.suggested_mentions"
          >
            <div v-for="mention in suggested_mentions">
              <div
                class="d-flex"
                style="cursor: pointer"
                @click="set_mention(mention.nick)"
              >
                <div>
                  <img
                    style="
                      margin-right: 5px;
                      width: 25px;
                      height: 25px;
                      border-radius: 50%;
                    "
                    :src="mention.avatar_url"
                    alt=""
                  />
                </div>
                {{ mention.name }} (@{{ mention.nick }})
              </div>
            </div>
          </div>
          <textarea
            rows="3"
            cols="33"
            v-model="new_comment"
            name=""
            :placeholder="$t('comments.caption')"
            @keyup.enter="sendComment()"
            @keydown="check_mention"
          ></textarea>

          <div
            v-if="this.parent_name"
            class="position-relative reply-text"
            style="width: max-content"
          >
            Відповісти {{ parent_name }}
            <i
              style="font-size: 12px; position: absolute; top: 0; right: -15px"
              class="fa-solid fa-xmark"
              @click="(this.parent_name = null), (this.parent_comment = null)"
            ></i>
          </div>
          <i
            class="fa-solid fa-paper-plane position-absolute"
            style="top: 18px; right: 0; color: #84be05"
            @click="sendComment()"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref } from "vue";
import { mapActions, mapGetters } from "vuex";
import amplitude from "amplitude-js";
export default {
  props: ["post", "show_more"],
  data() {
    return {
      offset_id: 0,
      comments: [],
      new_comment: "",
      parent_comment: null,
      parent_name: null,
      replies: null,
      reply_parent: null,
      comments_length: 10,
      save_symbols: false,
      saved_symbols: "",
      suggested_mentions: null,
    };
  },
  computed: {
    ...mapGetters(["ACCES_TOKEN", "USER_INFO", "HOST"]),
    userInfo() {
      return this.USER_INFO;
    },
    accessToken() {
      return this.ACCES_TOKEN;
    },
    host() {
      return this.HOST;
    },
  },
  methods: {
    ampl_profile_visit() {
      amplitude.getInstance().logEvent("Another user Profile Visit");
    },
    set_mention(nickname) {
      let those = this;
      let searchStr = `@${this.saved_symbols}`;
      let regex = new RegExp(searchStr + "(?!\\w)", "g");
      this.new_comment = this.new_comment.replace(regex, `@${nickname}`);
      those.suggested_mentions = null;
    },
    check_mention: function (e) {
      let those = this;
      if (e.key == "@") {
        this.save_symbols = true;
      } else if (e.key == " ") {
        this.save_symbols = false;
        this.saved_symbols = "";
        those.suggested_mentions = null;
      }

      var regex = /[^a-zA-Z0-9_]/;

      if (this.save_symbols) {
        if (!regex.test(e.key) && event.key.length == 1) {
          if (e.key !== "Backspace") {
            this.saved_symbols = this.saved_symbols + e.key;
          }
        } else if (e.key == "Backspace") {
          this.saved_symbols = this.saved_symbols.slice(0, -1);
        }

        this.saved_symbols = this.saved_symbols.replace(/@/g, "");
        if (this.saved_symbols !== "") {
          axios(`https://${those.host}/nick/complete?text=${this.saved_symbols}`, {
            method: "GET",
            headers: {
              Authorization: those.accessToken,
            },
          }).then((response) => {
            those.suggested_mentions = response.data.items;
            console.log(response.data.items);
            if (response.data.items.length == 0) {
              those.suggested_mentions = null;
            }
          });
        }
      }
    },
    click_mention: function (e) {
      let those = this;
      let mention_link = e.target.getAttribute("data-mention");
      mention_link = mention_link.replace(/@/g, "");
      if (mention_link !== null) {
        axios(`https://${those.host}/nick/user/${mention_link}`, {
          method: "GET",
          headers: {
            Authorization: those.accessToken,
          },
        }).then((response) => {
          this.$router.push(`/profile?user_id=${response.data.user.id}`);
        });
      }
    },
    close_modal() {
      this.$emit("close");
    },
    sendComment() {
      amplitude.getInstance().logEvent("Comments");
      let those = this;
      if (those.new_comment !== "") {
        axios(`https://${those.host}/comment`, {
          method: "POST",
          headers: {
            Authorization: those.accessToken,
          },
          data: {
            text: those.new_comment,
            post_id: those.post.id,
            parent_id: those.parent_comment,
          },
        }).then((response) => {
          const container = this.$refs.container;
          container.scrollTop = 0;
          those.offset_id = 0;
          those.get_comments();
          those.new_comment = "";
          those.parent_name = null;
          /*           let today = new Date();

          let year = today.getFullYear();
          let month = String(today.getMonth() + 1).padStart(2, "0");
          let day = String(today.getDate()).padStart(2, "0");

          let formattedDateTime = `${day}.${month}.${year}`;

          let comment_to_push = {
            created_at: `${formattedDateTime}`,
            text: `${those.new_comment}`,
            user: {
              id: `${those.userInfo.id}`,
              name: `${those.userInfo.name}`,
              avatar_url: `${those.userInfo.image}`,
            },
            my: {
              like: false,
            },
          };
          those.comments.unshift(comment_to_push);
          those.new_comment = "";
          those.parent_name = null; */
        });
      }
    },
    handleScroll() {
      const container = this.$refs.container;
      const scrollPosition = container.scrollTop + container.offsetHeight;
      const scrollHeight = container.scrollHeight;

      if (scrollPosition >= scrollHeight && this.comments_length > 0) {
        let those = this;
        those.offset_id == 0
          ? (those.offset_id = "&")
          : (those.offset_id = `${those.offset_id}&`);

        axios(
          `https://${those.host}/comments?post_id=${those.post.id}&cursor=${those.offset_id}limit=10&reverse=true`,
          {
            method: "GET",
            headers: {
              Authorization: those.accessToken,
            },
          }
        ).then((response) => {
          those.comments_length = response.data.comments.length;

          those.offset_id = encodeURIComponent(response.data.next_cursor);
          console.log(response.data.next_cursor);
          let new_comments = response.data.comments;
          new_comments.forEach((comment) => {
            comment.created_at = `${new Date(
              comment.created_at * 1000
            ).toLocaleDateString()}`;
            comment.text = comment.text.replace(
              /@(\w+)/g,
              '<span data-mention="@$1" style="color:#84be05; cursor: pointer;" class="mention">@$1</span>'
            );
            those.comments.push(comment);
          });
        });
      }
    },
    like_comment(id, liked) {
      amplitude.getInstance().logEvent("Likes");
      let those = this;
      let axios_method = "DELETE";
      if (liked.like == undefined || liked.like == false) {
        axios_method = "POST";
      }

      axios(`https://${those.host}/comment/${id}/like`, {
        method: `${axios_method}`,
        headers: {
          Authorization: those.accessToken,
        },
      }).then((response) => {
        console.log(response);
      });
    },
    get_comments() {
      let those = this;
      those.offset_id == 0
        ? (those.offset_id = "&")
        : (those.offset_id = `${those.offset_id}&`);
      axios(
        `https://${those.host}/comments?post_id=${those.post.id}&cursor=${those.offset_id}limit=10&reverse=true`,
        {
          method: "GET",
          headers: {
            Authorization: those.accessToken,
          },
        }
      ).then((response) => {
        those.offset_id = encodeURIComponent(response.data.next_cursor);
        those.comments = response.data.comments;
        those.comments.forEach((comment) => {
          comment.created_at = `${new Date(
            comment.created_at * 1000
          ).toLocaleDateString()}`;

          comment.text = comment.text.replace(
            /@(\w+)/g,
            '<span data-mention="@$1" style="color:#84be05; cursor: pointer;" class="mention">@$1</span>'
          );
        });
      });
    },
    get_replies(id) {
      let those = this;
      those.reply_parent = id;

      axios(
        `https://${those.host}/comments?post_id=${those.post.id}&parent_id=${id}&limit=100&reverse=true`,
        {
          method: "GET",
          headers: {
            Authorization: those.accessToken,
          },
        }
      ).then((response) => {
        those.replies = response.data.comments;
        those.replies.forEach((comment) => {
          comment.created_at = `${new Date(
            comment.created_at * 1000
          ).toLocaleDateString()}`;
          comment.text = comment.text.replace(
            /@(\w+)/g,
            '<span data-mention="@$1" style="color:#84be05; cursor: pointer;" class="mention">@$1</span>'
          );
        });
        console.log(those.replies);
      });
    },
  },
  async mounted() {
    this.get_comments();
  },
};
</script>

<style lang="scss" scoped>
.mention-list {
  -webkit-box-shadow: 1px 6px 33px 10px rgba(204, 204, 204, 1);
  -moz-box-shadow: 1px 6px 33px 10px rgba(204, 204, 204, 1);
  box-shadow: 1px 6px 33px 10px rgba(204, 204, 204, 1);
  border-radius: 8px;
}
.modal-container {
  position: fixed;
  z-index: 9999;
  top: 90px;
  right: 12px;
  bottom: 28px;
  /*   width: 100vw;
  height: 100vh; */
  /*   background: transparent;
  backdrop-filter: blur(6px); */
  display: flex;
  /*   justify-content: flex-end;
  align-items: center; */
  .custom-modal {
    position: relative;
    /*     width: 500px;
    height: 600px; */
    /* height: max-content; */
    box-shadow: 0px 4px 12px rgba(24, 28, 35, 0.16);
    background: #fff;
    border-radius: 24px;
    padding: 64px;

    .modal-head {
      .head-title {
        font-size: 32px;
        font-weight: 500;
        line-height: 38px;
      }
    }

    .modal-body {
      height: max-content;
      max-height: 85%;
      overflow-y: scroll;
      .comment-item {
        margin-top: 17px;
        .fire-white {
          color: #848682;
          cursor: pointer;
          &.active {
            color: #ea4335;
          }
        }
      }
      .user-info {
        display: flex;
        width: max-content;
        justify-content: space-between;
        align-items: start;
        text-decoration: none;
        width: 100%;

        .post-name {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #181c23;
        }

        .comment-time {
          font-family: "Barlow", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #848682;
        }
      }
      .comment-text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #181c23;
        margin-left: 45px;
        margin-top: 3px;
        .mention {
          color: #84be05 !important;
        }
      }

      .reply_comment {
        margin: 3px 0 0 45px;
        color: #848682;
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
      }
    }

    .modal-footer {
      position: absolute;
      width: 400px;
      bottom: 20px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      border-top: 1px solid #f8f8f8;
      padding-top: 12px;
      .new-comment {
        width: 100%;
        margin-left: 12px;
        input {
          width: 100%;
          border: none;
          outline: none;
        }
      }
      .reply-text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #181c23;
      }
    }
  }

  .close-btn {
    display: flex;
    width: 40px;
    height: 40px !important;
    padding: 8.571px;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    background: #f7f7f7;
  }

  textarea {
    resize: none;
    padding: 5px;
    border: none;
  }
}
</style>
