const messages = {
  en: {
    // Англійська локалізація
    // ...
    feed: {
      post_create_type: 'Photo / Video',
      training: 'Training',
      trip: "Trip",
      distance: "Distance",
      elevation_gain: "Elevation gain",
      elevation_loss: "Elevation loss",
      moving_time: "Est. moving time",
      meters: "m",
      hours: "h",
      minutes: "m",
      seconds: "s"

    },
    comments: {
      comments: "Comments",
      caption: "Add comment"
    },
    modal: {
      create: "Create new post",
      time: "moving time ",
      speed: "Speed",
      distance: "Distance",
      speed_kmh: "km/h",
      distance_km: "km",
      add_media: "Add photos/videos",
      dragn_drop: "or drag and drop",
      post: 'Post',
      caption: "Write a caption",
      notif: "Notifications",
      notif_sub: "started following you.",
      notif_comment: "comented:",
      notif_like_post_low: "like of your post",
      notif_like_post_high: "likes of your post",
      notif_like_comment_low: "like of your comment",
      notif_like_comment_high: "likes of your comment"
    },
    navbar: {
      home: "Home",
      roadies: "Roadies",
      trips: "Trips",
      routes: "Routes",
      logout: "Log out"
    },
    profile: {
      follow: "Follow",
      followed: "Followed",
      skill: "Beginner",
      followers: "Followers",
      following: "Following",
      likes: "Likes",
      photos: "Photos",
      vlogs: "Vlogs",
      cycling: "Cycling",
      travel: "Travel",
      friends: "Friends",
      food: "Food",
      all: "All",
      posts: "Posts",
      routes: "Routes",
      groups: "Groups",
      posts: "Posts"
    },
    routes: {
      activity: "My Activity",
      details: "Track details",
      dirty: "Dirt roads",
      velo: "Bicycle paths",
      layers: "Layers",
      research: "Research",
      tracks_show: "Tracks",
      heatmap: "Heatmap"
    },

  },
  ua: {
    // Українська локалізація
    // ...
    feed: {
      post_create_type: 'Фото / Відео',
      training: 'Тренування',
      trip: "Подорож",
      distance: "Дистанція",
      elevation_gain: "Підвищення висоти",
      elevation_loss: "Втрата висоти",
      moving_time: "Приблизно час руху",
      meters: "м",
      hours: "г",
      minutes: "хв",
      seconds: "с"
    },
    comments: {
      comments: "Коментарі",
      caption: "Додати коментар"
    },
    modal: {
      create: "Створення публікації",
      time: "Пройдений час",
      speed: "Швидкість",
      distance: "Відстань",
      speed_kmh: "км/г",
      distance_km: "км",
      add_media: "Додати фото/відео",
      dragn_drop: "Або перетягніть",
      post: 'Опублікувати',
      caption: "Додати опис",
      notif: "Сповіщення",
      notif_sub: "підписався(-лась) на Вас.",
      notif_comment: "прокоментував(-ла).",
      notif_like_post_low: "вподобання Вашого поста.",
      notif_like_post_high: "вподобаннь Вашого поста.",
      notif_like_comment_low: "вподобання Вашого коментаря.",
      notif_like_comment_high: "вподобаннь Вашого коментаря."
    },
    navbar: {
      home: "Головна",
      roadies: "Roadies",
      trips: "Подорожі",
      routes: "Маршрути",
      logout: "Вихід"
    },
    profile: {
      follow: "Підписатись",
      followed: "Ви підписані",
      skill: "Початківець",
      followers: "Підписники",
      following: "Підписки",
      likes: "Вподобайки",
      photos: "Фото",
      vlogs: "Влоги",
      cycling: "Велоспорт",
      travel: "Подорожі",
      friends: "Друзі",
      food: "Їжа",
      all: "Всі",
      posts: "Публікації",
      routes: "Маршрути",
      groups: "Групи",
      posts: "Пости"
    },
    routes: {
      activity: "Моя активність",
      details: "Деталі треку",
      dirty: "Грунтові дороги",
      velo: "Велодоріжки",
      layers: "Шари",
      research: "Дослідження",
      tracks_show: "Треки",
      heatmap: "Теплова мапа"
    },
  },
};

export default messages;