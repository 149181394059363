<template>
  <div id="sidebar">
    <div class="sidebar-title">Sidebar title</div>
    <div class="sidebar-item"></div>
    <div class="sidebar-item"></div>
    <div class="sidebar-item"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
#sidebar {
  width: 320px;
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
  position: absolute;
  top: 90px;
  right: 12px;
  bottom: 28px;
  border-radius: 32px;
  z-index: 2;

  .sidebar-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #181c23;
  }

  .sidebar-item {
    width: 272px;
    height: 160px;
    background: var(--bg-block);
    border-radius: 8px;
    margin: 20px 0 0;
  }
}
</style>
