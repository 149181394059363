import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/login.vue'
import Home from '../views/home.vue'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/roadies',
    name: 'roadies',
    component: function () {
      return import('../views/roadies.vue')
    }
  },
  {
    path: '/trips',
    name: 'trips',
    component: function () {
      return import('../views/trips.vue')
    }
  },
  {
    path: '/routes',
    name: 'routes',
    component: function () {
      return import('../views/routes.vue')
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: function () {
      return import('../views/profile.vue')
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'hash',
  routes: routes,
/*   deep: true */
})

export default router
