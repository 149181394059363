<template>
  <div class="modal-container">
    <div class="custom-modal">
      <div class="modal-head d-flex justify-content-between align-items-center">
        <div class="head-title">{{ $t("modal.notif") }}</div>
        <div class="close-btn" style="height: max-content" @click="close_modal()">
          <i class="fas fa-times" style="font-size: 16px"></i>
        </div>
      </div>
      <div class="modal-body">
        <div class="notification">
          <div class="notification-item position-relative" v-for="item in notif">
            <div class="created-at">
              {{ item.created_at_date }}
            </div>
            <div class="d-flex" v-if="item.user">
              <router-link class="user-info" :to="`/profile?user_id=${item.user.id}`">
                <div class="d-flex">
                  <img
                    style="width: 48px; height: 48px; border-radius; border: 0.935897px solid #AEE140; border-radius: 94px;"
                    :src="item.user.avatar_url"
                    alt=""
                  />
                  <div style="margin-left: 5px">
                    <div class="post-name">
                      {{ item.user.name }}
                    </div>
                    <div class="notification-text" v-if="item.event == 'new-follower'">
                      {{ $t("modal.notif_sub") }}
                    </div>
                    <div class="notification-text" v-if="item.event == 'new-comment'">
                      {{ $t("modal.notif_comment") }}
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link v-if="item.event == 'new-comment' || item.event == 'comment-like'"
                :to="`/profile?user_id=${userInfo.id}&open_post=${item.post.id}`"
              >
                <img
                  style="width: 48px; height: 48px; border-radius; border: 0.935897px solid #AEE140; border-radius: 94px;"
                  :src="item.post.preview_image_url"
                  alt=""
                />
              </router-link>
              <div class="left-side mx-3">
                <div class="notific-time">
                  {{ getNotificTime(item.created_at) }}
                </div>
              </div>
            </div>
            <div class="d-flex" v-if="!item.user">
              <div class="user-info">
                <div class="d-flex">
                  <router-link
                  v-if="item.post"
                    :to="`/profile?user_id=${userInfo.id}&open_post=${item.post.id}`"
                  >
                    <img
                      style="width: 48px; height: 48px; border-radius; border: 0.935897px solid #AEE140; border-radius: 94px;"
                      :src="item.post.preview_image_url"
                      alt=""
                    />
                  </router-link>
                  <div style="margin-left: 5px">
                    <div
                      class="notification-text"
                      v-if="item.event == 'post-like' && item.count < 5"
                    >
                      {{ item.count }} {{ $t("modal.notif_like_post_low") }}
                    </div>
                    <div
                      class="notification-text"
                      v-if="item.event == 'post-like' && item.count >= 5"
                    >
                      {{ item.count }} {{ $t("modal.notif_like_post_high") }}
                    </div>
                    <div
                      class="notification-text"
                      v-if="item.event == 'comment-like' && item.count < 5"
                    >
                      {{ item.count }} {{ $t("modal.notif_like_comment_low") }}
                    </div>
                    <div
                      class="notification-text"
                      v-if="item.event == 'comment-like' && item.count >= 5"
                    >
                      {{ item.count }} {{ $t("modal.notif_like_comment_high") }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="left-side mx-3">
                <div class="notific-time">
                  {{ getNotificTime(item.created_at) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref } from "vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      offset_id: 0,
      notif: null,
      groupedDates: {},
      active_post: null,
    };
  },
  computed: {
    ...mapGetters(["ACCES_TOKEN", "USER_INFO", "HOST"]),
    userInfo() {
      return this.USER_INFO;
    },
    accessToken() {
      return this.ACCES_TOKEN;
    },
    host() {
      return this.HOST;
    },
  },
  methods: {
    close_modal() {
      this.$emit("close");
    },
    getNotificTime(created_at) {
      let date = new Date(created_at * 1000);
      let hours = date.getHours();
      let minutes = date.getMinutes();

      let formattedTime = hours + ":" + (minutes < 10 ? "0" : "") + minutes;
      return formattedTime;
    },
  },
  async mounted() {
    let those = this;
    those.offset_id == 0
      ? (those.offset_id = "")
      : (those.offset_id = `${those.offset_id}&`);
    axios(`https://${those.host}/my/notifications?${those.offset_id}limit=50`, {
      method: "GET",
      headers: {
        Authorization: those.accessToken,
      },
    }).then((response) => {
      those.notif = response.data.notifications;
      those.notif.forEach((notific) => {
        const itemDateObj = new Date(notific.created_at * 1000);

        const formattedDate = itemDateObj.toLocaleDateString();

        if (!this.groupedDates[formattedDate]) {
          this.groupedDates[formattedDate] = true;
          notific.created_at_date = formattedDate;
        } else {
          notific.created_at_date = "";
        }
      });

      console.log(those.notif);
    });
  },
};
</script>

<style lang="scss" scoped>
.notific-time {
  color: #696969;
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.created-at {
  color: #181c23;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 15px;
}

.modal-container {
  position: fixed;
  z-index: 9999;
  top: 90px;
  right: 12px;
  bottom: 28px;
  /*   width: 100vw;
  height: 100vh; */
  /*   background: transparent;
  backdrop-filter: blur(6px); */
  display: flex;
  /*   justify-content: flex-end;
  align-items: center; */
  .custom-modal {
    position: relative;
    /*     width: 500px;
    height: 600px; */
    /* height: max-content; */
    box-shadow: 0px 4px 12px rgba(24, 28, 35, 0.16);
    background: #fff;
    border-radius: 24px;
    padding: 64px;
    z-index: 3;

    .modal-head {
      .head-title {
        font-size: 32px;
        font-weight: 500;
        line-height: 38px;
      }
    }

    .notification-time {
      font-family: "Barlow", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #848682;
    }

    .modal-body {
      height: max-content;
      max-height: 85%;
      overflow-y: scroll;
      .notification-item {
        margin-top: 17px;
        .fire-white {
          color: #848682;
          cursor: pointer;
          &.active {
            color: #ea4335;
          }
        }
      }
      .user-info {
        display: flex;
        width: max-content;
        justify-content: space-between;
        align-items: start;
        text-decoration: none;
        width: 100%;

        .post-name {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #181c23;
        }
      }
      .notification-text {
        text-overflow: ellipsis;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #181c23;
        /* margin-left: 45px; */
        margin-top: 3px;
      }
    }

    .modal-footer {
      position: absolute;
      width: 400px;
      bottom: 20px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      border-top: 1px solid #f8f8f8;
      padding-top: 12px;
      .new-comment {
        width: 100%;
        margin-left: 12px;
        input {
          width: 100%;
          border: none;
          outline: none;
        }
      }
    }
  }

  .close-btn {
    display: flex;
    width: 40px;
    height: 40px !important;
    padding: 8.571px;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    background: #f7f7f7;
  }
}
</style>
