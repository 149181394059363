<template>
  <div class="post-info-modal">
    <div class="post-info-head">
      <div class="feed-head">
        <router-link
          class="user-info"
          :to="`/profile?user_id=${this.post.post_info.user.id}`"
        >
          <img
            style="width: 40px; height: 40px; border-radius; border: 0.935897px solid #AEE140; border-radius: 94px;"
            :src="this.post.post_info.user.image"
            alt=""
          />
          <div style="margin-left: 5px">
            <div class="post-name">
              {{ this.post.post_info.user.name }}
            </div>
            <div class="post-time">
              {{ this.post.post_info.published_at }}
            </div>
          </div>
        </router-link>
      </div>
      <div class="close-info" @click="close_info()">
        <i class="fa-solid fa-xmark"></i>
      </div>
    </div>
    <div class="media-container">
      <div
        class="post-arrows"
        v-if="this.post.post_info.media.length > 1"
        style="left: 12px"
        @click="prev_photo()"
      >
        <i class="fa-solid fa-chevron-left"></i>
      </div>
      <div class="active-media">
        <img :src="this.post.active_media" alt="" />
        <div class="media-footer d-flex">
          <div
            class="feed-track-info"
            @click="
              likePost(this.post.post_info.id, this.post.post_info.my.like),
                (this.post.post_info.my.like = !this.post.post_info.my.like)
            "
          >
            <i
              style="font-size: 16px; cursor: pointer"
              class="fa-solid fa-fire"
              :class="{ liked: this.post.post_info.my.like }"
            ></i>
          </div>
          <router-link
            v-if="this.post.post_info.trip.length && this.post.post_info.trip[0].track"
            class="feed-track-info mx-4"
            :to="`/routes?id=${this.post.post_info.trip[0].track.id}`"
          >
            <i class="fas fa-route"></i>
          </router-link>
        </div>
      </div>
      <div
        class="post-arrows"
        v-if="this.post.post_info.media.length > 1"
        style="right: 12px"
        @click="next_photo()"
      >
        <i class="fa-solid fa-chevron-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["post"],
  mounted() {
    console.log(this.post);
  },
  methods: {
    close_info() {
      this.$emit("close_info");
      if (this.open_post) {
        this.SET_FORCE_ROUTE(false);
        this.$router.push("/");
      } else {
        
      }
    },
    prev_photo() {
      const searchKey = "url";
      const searchValue = this.post.active_media;

      const index = this.post.post_info.media.findIndex(
        (obj) => obj[searchKey] === searchValue
      );
      const prev_index = index - 1 < 0 ? this.post.post_info.media.length - 1 : index - 1;
      this.post.active_media = this.post.post_info.media[prev_index].url;
    },
    next_photo() {
      const searchKey = "url";
      const searchValue = this.post.active_media;

      const index = this.post.post_info.media.findIndex(
        (obj) => obj[searchKey] === searchValue
      );
      const prev_index = index + 1 > this.post.post_info.media.length - 1 ? 0 : index + 1;
      this.post.active_media = this.post.post_info.media[prev_index].url;
    },
    likePost(id, like) {
      let data = [id, like];
      this.$emit("like_post", data);
    },
  },
};
</script>

<style lang="scss">
.liked {
  color: #ea4335;
}

.post-info-modal {
  position: fixed;
  top: 0;
  z-index: 999;
  height: 100vh;
  width: 100vw;
  background: rgba(24, 28, 35, 0.88);
  /*   backdrop-filter: blur(10px); */
  .post-info-head {
    height: 64px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 13px 8px 12px;
    .close-info {
      display: flex;
      width: 40px;
      height: 40px;
      padding: 8.571px;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      background: rgba(255, 255, 255, 0.12);
      cursor: pointer;
      & {
        i {
          transition-duration: 0.5s;
        }
      }
      &:hover {
        & {
          i {
            color: #fff;
          }
        }
      }
    }
  }

  .media-container {
    width: calc(100vw - 562px);
    position: relative;
    .post-arrows {
      display: flex;
      width: 40px;
      height: 40px;
      padding: 8.571px;
      justify-content: center;
      align-items: center;
      position: absolute;
      border-radius: 24px;
      background: rgba(255, 255, 255, 0.12);
      top: 40%;
      cursor: pointer;
      & {
        i {
          transition-duration: 0.5s;
        }
      }
      &:hover {
        & {
          i {
            color: #fff;
          }
        }
      }
    }
  }

  .feed-track-info {
    margin-top: 10px;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.12);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    i {
      color: #fff;
      font-size: 16px;
      &.liked {
        color: #ea4335;
      }
    }
  }

  .active-media {
    margin: 24px auto;
    width: max-content;

    height: calc(100vh - 126px);
    img {
      height: calc(100% - 64px);
      border-radius: 28px;
      /* max-width: 850px; */
      max-height: 850px;
      object-fit: cover;
    }
  }
}

.feed-head {
  padding: 6px 16px 6px 4px;
  display: flex;
  justify-content: space-between;
  /* position: absolute; */
  height: max-content;
  /*   top: 8px;
  left: 10px;
  z-index: 2; */
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(8px);

  .user-info {
    display: flex;
    width: max-content;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;

    .post-name {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #fff;
    }

    .post-time {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #fff;
    }
  }
}
</style>
