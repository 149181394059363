<template>
  <div id="home">
    <Header />
    <Navbar
      @change_page="change_page"
      @create_post="create_post"
      :active_page="nav_page"
      @logout="$emit('logout')"
    />
    <Feed v-if="nav_page === 0" @go_to_trip="trip_open" />
    <!-- <Chat /> -->
    <!-- <Sidebar v-if="nav_page === 0 || nav_page === 1" /> -->
    <!-- <Trips v-if="nav_page === 2" @create_post="create_post" :handle_click="handle_click"/> -->
    <Modal
      v-if="this.modal"
      :post_id="this.post_id"
      @close="close_modal()"
      :accessToken="this.accessToken"
      :trip="this.activeTrip"
    />

  </div>
</template>

<script>
import { db } from "@/main";
import { collection, getDocs } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Header from "@/components/header.vue";
import Navbar from "@/components/navbar.vue";
import Sidebar from "@/components/sidebar.vue";
import Feed from "@/components/feed.vue";
import Chat from "@/components/chat.vue";

import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Header,
    Navbar,
    Sidebar,
    Feed,
    Chat,
  },
  data() {
    return {
      theme: "light",
      desc_show: "",
      modal: false,
      nav_page: 0,
      post_id: null,
      activeTrip: null,
      handle_click: null
    };
  },
  computed: {
    ...mapGetters(["ACCES_TOKEN", "USER_INFO", "LANG"]),
    userInfo() {
      return this.USER_INFO;
    },
    accessToken() {
      return this.ACCES_TOKEN;
    },
    userLang() {
      return this.LANG
    }
  },
  methods: {
    changeTheme: function (e) {
      let those = this;
      let target = document.querySelector(".theme");
      let page_html = document.querySelector("html");
      page_html.classList.toggle("light");
      page_html.classList.toggle("dark");
      target.classList.toggle("light");
      target.classList.toggle("dark");

      setTimeout(function () {
        those.theme == "light"
          ? (those.theme = "dark")
          : (those.theme = "light");
      }, 450);
    },
    close_modal() {
      this.modal = false;
    },
    change_page(item) {
      this.nav_page = item;
    },
    create_post(post_info) {
      this.post_id = post_info[0];
      this.modal = true;
      this.activeTrip = post_info[1];
    },
    trip_open(id) {
      
      this.nav_page = 2;
      this.handle_click = id
    },
  },
  created() {},
};
</script>

<style lang="scss">
#home {
  background: var(--main-bg);
  height: 100vh;
}
</style>
