<template>
  <router-view @logout="handleSignOut()" :key="$route.fullPath"/>
</template>

<script>
import { onMounted, ref } from "vue";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import amplitude from "amplitude-js";
let auth;

export default {
  components: {},
  data() {
    return {
      isLoggedIn: false,
      user_info: {},
      accessToken: null,
    };
  },
  mounted() {
    let those = this;
    auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        axios(`https://${those.host}/auth/login`, {
          method: "POST",
          data: {
            google_token: user.accessToken,
          },
        })
          .then((response) => {
            those.accessToken = response.data.token;
            axios(`https://${those.host}/me`, {
              method: "GET",
              headers: {
                Authorization: response.data.token,
              },
            })
              .then((response) => {
                those.on_login(those.accessToken, response.data.user);
              })
              .catch((error) => {
                console.log(error);
                if (error) {
                  signOut(auth).then(() => {
                    those.$router.push("/login");
                  });
                }
              });
          })
          .catch((error) => {
            console.log(error);
          });

        this.$router.push("/");
      } else {
        this.$router.push("/login");
      }
    });
  },
  methods: {
    ...mapActions(["SET_ACCES_TOKEN", "SET_USER_INFO"]),
    on_login(token, info) {
      this.SET_ACCES_TOKEN(token);
      this.SET_USER_INFO(info);
    },
    handleSignOut() {
      amplitude.getInstance().logEvent("Logout");
      signOut(auth).then(() => {
        this.$router.push("/login");
      });
    },
  },
    computed: {
    ...mapGetters(["HOST"]),
    host() {
      return this.HOST;
    },
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

html.light {
  --main-bg: #f7f7f7;
  --header: #fff;
  --main-text: #2d3441;
  --icon-color: #000;
  --bg-block: #fff;
  --nav-hover: #f7f7f7;
  --notif: #fff;
  --notif-button-bg: #0d0f0a;
  --theme-track: #f7f7f7;
}

html.dark {
  --main-bg: #181c23;
  --header: #181c23;
  --main-text: #fff;
  --icon-color: #fff;
  --bg-block: #2d3441;
  --nav-hover: #2d3441;
  --notif: #2d3441;
  --notif-button-bg: #fff;
  --theme-track: #2d3441;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-button {
  background-color: #666;
  height: 0;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-track-piece {
  background-color: var(--blackColor);
}

::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #666;
  border-radius: 3px;
}

::-webkit-scrollbar-corner {
  background-color: #999;
}

::-webkit-resizer {
  background-color: #666;
}
</style>
